import React, {useState, useEffect} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {  ServerUrl,  ImagesUrl, Token, config, allowDelete, allowEdit, menuLink, API_URL} from '../component/include'
import Select from 'react-select'

import { useSelector } from 'react-redux'
import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { formatGroupLabel} from '../component/globalFunction'
const StudentAttendance =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
  
    
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [CurrentSession, setCurrentSession] = useState('')
    const [schoolClass, setSchoolClass]=useState([]);
    const [staff, setStaff]= useState([])
    const [subject, setSubject]= useState([])
    const [students, setStudents]= useState([])
    const [attendance, setAttendance] = useState({
        sectionID:{value:'', label:''},
        subject:{value:'', label:''},
        attendanceType:'fullday',
        orderBy:{value:'order by studentName ASC', label:'order by Name'},
        attendanceDate:'',
        sessionYear:CurrentSession,
        staff:{value:'', label:''}
    })


    const  handleSession =()=>{
    
        var sql ="Select  startYear from tbl_school_session where status ='Active'"
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config)
        .then(result => {
            if(result.data.length!==0){
            setCurrentSession(result.data[0].startYear)
        }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        } 
    
 const  handleFetchClass =()=>{
    const fd = new FormData();
    fd.append('jwt', Token); 
    let url = ServerUrl + '/fetchField/tbl_school_class/schoolClass/ID/1'
    axios.post(url, fd, config)
    .then(result => setSchoolClass(result.data))
    .catch((error)=>Alerts('Error!', 'danger', JSON.stringify(error.message))) 
     }

     const fetchStaff =()=>{
        const fd = new FormData();
        fd.append('jwt', Token); 
        let url = ServerUrl+'/fetch_controller/tbl_staff'
        axios.post(url, fd, config).then(result=>setStaff(result.data))
    }

    const fetchSubject =()=>{
        const fd = new FormData();
            fd.append('jwt', Token); 
        let url = ServerUrl+'/fetch_controller/tbl_subject'
        axios.post(url, fd, config).then(result=>setSubject(result.data))
    }


    const fetchAllHolidays =(value)=>{
       
        const fd = new FormData();
        fd.append('jwt', Token);
        let sql = 'Select startDate from tbl_events_holidays where eventType= "Holidays" and startDate = "'+value+'"'

            let url = ServerUrl+'/fetchBySql_controller'
            fd.append("sql", sql)
            axios.post(url, fd, config).then(response =>{
                if(Array.isArray(response.data)&& response.data.length!==0){
                    setErrors({...errors, attendanceDate:'Selected date is an Holiday'})
                    setAttendance({...attendance, attendanceDate:''})
                }
            }) 
    }
    const fetchStudent = event =>{
        event.preventDefault(); 

        let errors = {};     
           
        
       
        if(!attendance.attendanceDate===''){
            errors.attendanceDate ='Please select attendance date ';
        }

        if(!attendance.sectionID.value===''){
            errors.sectionID ='Please select Student Class';
        }

        const theDate = new Date(attendance.attendanceDate)
        if (theDate.getDay() === 0 || theDate.getDay() === 6) {
            errors.attendanceDate ='Selected date is an Holiday';
        }

        setErrors(errors)
      
        if(Object.keys(errors).length === 0){
        setNotice({...notice, isLoading: true}) 
        var sql ="SELECT  code, studentName, classID, termination, sectionID, admissionNumber, gender, passport from tbl_students where admissionStatus ='Studying' and  sectionID = '"+attendance.sectionID.value+"' "+attendance.orderBy.value
        
        const fd = new FormData();
        fd.append('jwt', Token); 
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>{
        if(result.data!==0){

         var options = result.data;
            var value = [];
            for (var i = 0, l = options.length; i < l; i++) {
              value.push({code:options[i].code, studentName:options[i].studentName, admissionNumber:options[i].admissionNumber, gender:options[i].gender, passport:options[i].passport, classID:options[i].classID, sectionID:options[i].sectionID, indicator:'P'});
            }

    setStudents(value); 
        }else{
            Alerts('Info!', 'default', 'There are no studet found')  
        }
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    })
    .finally(()=>{
        setNotice({...notice, isLoading: false})
    })
    }
}

const handleSelect= (option, action)=>{
        setAttendance({...attendance, [action.name]:option});
        setErrors({...errors, [action.name]:''})
        setStudents('')
    }

    const handleChange=(event)=>{
        setAttendance({...attendance, [event.target.name]:event.target.value});
        setErrors({...errors, [event.target.name]:''})
        setStudents('')
        
        fetchAllHolidays(event.target.value)
    }

    const getToday =()=>{
        var date = new Date();
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        
        if (month < 10) month = "0" + month;
        if (day < 10) day = "0" + day;
        
        var today = year + "-" + month + "-" + day; 
    
        fetchAllHolidays(today)
   setAttendance({...attendance,
        attendanceDate:today
        })
    }


    const handleMarkAttendance=(e, index)=>{
        const std =JSON.parse(JSON.stringify(students))         
        std[index].indicator = std[index].indicator ==='P'?'H':std[index].indicator ==='H'?'A':std[index].indicator ==='A'?'L':'P';
       setStudents(std);
    }


const handleMarkAll=()=>{
    var options = students;
            var value = [];
            for (var i = 0, l = options.length; i < l; i++) {
                value.push({code:options[i].code, studentName:options[i].studentName, admissionNumber:options[i].admissionNumber, gender:options[i].gender, passport:options[i].passport, classID:options[i].classID, sectionID:options[i].sectionID, indicator:options[i].indicator ==='P'?'H':options[i].indicator ==='H'?'A':options[i].indicator ==='A'?'L':'P'});
              }
       setStudents(value);
}


         function handleSubmit(){  
            const fd = new FormData();
            fd.append('studentList', JSON.stringify(students, null, 2));
           fd.append('attendanceDate', attendance.attendanceDate);
           fd.append('sessionYear', CurrentSession);
           setNotice({...notice,  isLoading: true}) 
       
              let url = API_URL+'/student/attendance/create'
             axios.post(url, fd, config)
             .then(response =>{
               if(response.data.status ===200){
                   Alerts('Saved!', 'success', response.data.message)
                       }  else{
                   Alerts('Error!', 'danger', JSON.stringify(response.data))
                       }   
               })
               .catch((error)=>{
                 Alerts('Error!', 'danger', error.message)
               }).finally(()=>{
                   setNotice({...notice, 
                       isLoading: false
                   })
                   setStudents('')
                   setAttendance({...attendance, 
                    sectionID:{value:'', label:''},
                   subject:{value:'', label:''},
                   attendanceType:'fullday',
                   orderBy:{value:'order by studentName ASC', label:'order by Name'},
                   staff:{value:'', label:''}
                })
               }) 
              
        }



useEffect(()=>{
    setIsAuthenticated(menuLink.includes(location.pathname))
  handleFetchClass()
getToday()
    fetchSubject()
    fetchStaff()
    handleSession()
},[]);



        return (  
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Student Attendance">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-calendar"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Attendance</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Student Attendance</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">



                <div className="row  ">
						
                        <div className="col-sm-12">
                    
                        <div className="card z-depth-0">
                            <div className="card-header">
                                <h5><i className="fa fa-edit" ></i> Create Attendance</h5>
                                <div className="card-header-right">
                                    <ul className="list-unstyled card-option">
                                        <li><i className="feather icon-maximizes full-card"></i></li>
                                        <li><i className="feather icon-minus minimize-card"></i></li>
                                        <li><i className="feather icon-trash-2 close-card"></i></li>
                                    </ul>
                                </div>
                            </div>
                            
                            
                            <div  className="card-block">
		
        <div className="row">
        <section className="col-md-6">
            <div className="form-group">
          <label> Student Class<span style={{color:'red'}}>*</span></label>
          <Select  options={
                             schoolClass.map((list, idx)=> {
                                return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                    return sec.sname !==''? {key:id, classID:list.ID,isSection:'1', value: sec.ID, label: sec.sname}:{classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                                }) }
                              })
                         } 
                         formatGroupLabel={formatGroupLabel}
 onChange={handleSelect} className={errors.sectionID ? 'form-control form-control-danger' : ''} name="sectionID" value={attendance.sectionID}  /> <span style={{color:'red'}}>{errors.sectionID && errors.sectionID}</span>
             </div>

      </section>
      
      <section className="col-md-6">
   

      <div className="form-group">
<label >Attendance Date <span style={{color:'red'}}>*</span></label>
<input type="date" className={errors.attendanceDate ? 'form-control form-control-danger' : 'form-control'} name="attendanceDate"  onChange={handleChange} value={attendance.attendanceDate} />
<span style={{color:'red'}}>{errors.attendanceDate && errors.attendanceDate}</span>
   </div>

   

</section>
{/* 
<section className="col-md-6">
            <div className="form-group">
          <label> Order</label>
          <Select  options={[
             {value:'order by studentName ASC', label:'order by Name'},
                    { value: 'order by registerNumber ASC', label: 'Order By Register Number'},
             { value: 'order by admissionNumber ASC', label: 'Order By Admission Number'},
                ]} 
onChange={handleSelect}  name="orderBy" value={attendance.orderBy}  /> 

 
             </div>
      
      </section>
<section className="col-md-6">
<div className="form-group">
                    <label >Type</label>
                    
                    <div className="form-radio">
                <div className="radio radio-inline"><label>
						<input type="radio" checked={attendance.attendanceType ==="fullday"} onChange={handleChange} value="fullday" name="attendanceType" />
										<i className="helper"></i>Full Day</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={attendance.attendanceType ==="subject"} onChange={handleChange} value="subject" name="attendanceType"  />
										<i className="helper"></i>Subject Attendance</label></div>

                    </div>
                    
                    </div>

</section>		
      <section className="col-md-6" style={{display:attendance.attendanceType ==="fullday"?'none':'block'}}>


      <div className="form-group">
						<label >Subject<span style={{color:'red'}}>*</span></label>
                        <Select options={
                            subject&&subject.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.subjectName, abr:list.abbreviation }
                             })} 
                             getOptionLabel={option =>`${option.label} - ${option.abr}`}
onChange={handleSelect} className={errors.subject ? 'form-control form-control-danger' : ''} name="subject" value={attendance.subject}  /> <span style={{color:'red'}}>{errors.subject && errors.subject}</span>	
			</div>
      
      </section>

      <section className="col-md-6" style={{display:attendance.attendanceType ==="fullday"?'none':'block'}}>
            <div className="form-group">
          <label> Staff</label>
          <Select  options={
                            staff.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.mobile, staffName:list.staffName}
                             })} 
                        getOptionLabel={option =>`(${option.label}) ${option.staffName}`}
onChange={handleSelect} className={errors.staff ? 'form-control form-control-danger' : ''} name="staff" value={attendance.staff}  /> <span style={{color:'red'}}>{errors.staff && errors.staff}</span>
 
             </div>
      
      </section>
 */}
				</div>	

<div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={fetchStudent} className="btn btn-primary "><i className="fa fa-search"></i> Get Students</button>
								</footer>		</section>		
							</div>
                            
                            
                            </div>
                                                   
                           </div>	</div>
                    
                           </div>




{students.length!==0?
        <div className="row  ">
        

        <div className="col-sm-12">
    
        <div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-edit" ></i> Mark Attendance</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximize full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            
            <section className="col-md-12">
            <span className="pcoded-badge label bg-simple-c-pink">Color Icon For Absent</span>
            
        <span className="pcoded-badge label label-info">Color Icon For Half Day</span>
            <span className="pcoded-badge label bg-simple-c-green">Color Icon For Present</span>
            </section>
    
            <div className="card-block">
            <div className="row" style={{backgroundColor:'yellow'}}>
        {students&&students.map((std, id)=>
        <section key={id} className="col-md-3"><br/>
             <button type="button" onClick={(e)=>handleMarkAttendance(e, id)} className={std.indicator==='P'?'btn btn-success':std.indicator==='A'?'btn btn-danger':std.indicator==='H'?'btn btn-info':'btn btn-default'} style={{width:'100%'}}>

            <img className="img-100" style={{height:'100px', width:'100px'}} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/students/'+std.gender+'.png'}} src={`${ImagesUrl}/students/${std.passport}`} alt="" />

            <div ><b>{ std.studentName.length>20?std.studentName.slice(0, 20)+'...':std.studentName}</b></div>
            <label>({std.admissionNumber})</label>           
            </button><label>&nbsp;</label>
            </section>
            
)}
          
</div>

<div className="row">
        <footer className="col-md-12 pull-right">
	<hr/>
	<button type="button"  onClick={handleMarkAll} className="btn btn-inverse "><i className="fa fa-edit"></i> PresentAll/AbsentAll</button>&nbsp;&nbsp;
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-success "><i className="fa fa-save"></i> Submit</button>
								</footer>
				</div>
                </div>
 </div> 
</div>

</div>:''}
	




   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(StudentAttendance) 